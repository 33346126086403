import React, { memo, useEffect, useMemo } from 'react'
import styles from './LedarParkMasterPlan.module.css'
let width = 5760
let height = 3240
let gridSize = [24, 13]
let link = 'park'

const preloadImages = (gridSize: number[], link: string) => {
    ;[...Array(gridSize[0])].forEach((_, index) => {
        ;[...Array(gridSize[1])].forEach((_, index2) => {
            const imagePreload = `/assets/tiles/${link}/tiles_${index + 1}_${index2 + 1}.webp`

            //@ts-ignore
            if (!window[imagePreload]) {
                const newImage = new Image()
                newImage.src = imagePreload
                //@ts-ignore
                window[imagePreload] = newImage
            }
        })
    })
}

export default memo(() => {
    useEffect(() => {
        preloadImages(gridSize, link)
    }, [])

    return (
        <>
            {[...Array(gridSize[0])].map((_, index) => {
                return [...Array(gridSize[1])].map((_, index2) => {
                    const tileWidth = width / gridSize[0]
                    const tileHeight = height / gridSize[1]

                    return (
                        <img
                            // className={'zoomLevel1 zoomLevel2 zoomLevel3 zoomLevel4 zoomLevel5'}
                            src={`/assets/tiles/${link}/tiles_${index + 1}_${index2 + 1}.webp`}
                            alt=""
                            key={`tile-${index}-${index2}`}
                            style={{
                                background: 'red',
                                transition: 'all .5s ease-in-out',
                                // background: 'transparent',
                                width: `${tileWidth}px`,
                                height: `${tileHeight}px`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                pointerEvents: 'visible',
                                position: 'absolute',
                                top: `${index2 * tileHeight}px`,
                                left: `${index * tileWidth}px`,
                                transform: 'scale(1.01)',
                                transformOrigin: 'top left',
                            }}
                        />
                    )
                })
            })}
        </>
    )
})
