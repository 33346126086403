import { useContext, useState } from 'react'

import styles from './MobileTabletHomePage.module.css'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import Layout from '../../../components/Layout/Layout'
import { PageContext } from '../../../contexts'
import {
  HomeLogoIcon,
  VideoIcon,
  HomeArrowRightIcon,
  GalleryIcon,
  DocumentsIcon,
  LedarLogoIconSmall,
} from '../../../icons/icons'
import DocumentPage from '../../DocumentPage/DocumentPage'
import GalleryPage from '../../GalleryPage/GalleryPage'
import VideoPage from '../../VideoPage/VideoPage'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

// import stylesG from './../../../GlobePage/GlobePage.module.css'

import stylesG from './../../GlobePage/GlobePage.module.css'

const MobileTabletHomePage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [activePage, setActivePage] = useState('home')
  const [showShadow, setShowShadow] = useState({
    video: false,
    gallery: false,
    documents: false,
    arrow_video: false,
    arrow_gallery: false,
    arrow_documents: false,
  })

  const pageContext = useContext(PageContext)

  const { resetButtons, setActiveButton } = pageContext

  const handleClick = () => {
    navigate('/country')
    resetButtons()
    setActiveButton(null)
  }

  const handleMouseEnter = (buttonName: string) => {
    setShowShadow({
      ...showShadow,
      [buttonName]: true,
      [`arrow_${buttonName}`]: true,
    })
  }

  const handleMouseLeave = (buttonName: string) => {
    setShowShadow({
      ...showShadow,
      [buttonName]: false,
      [`arrow_${buttonName}`]: false,
    })
  }

  const handlePageChange = (newPage: string) => {
    setActivePage(newPage)
  }

  const goBackToHome = () => {
    setActivePage('home')
  }

  const handleVideoError = () => {
    const videoContainer = document.querySelector(`.${styles.videoBackground}`)
    if (videoContainer) {
      videoContainer.classList.add(styles.videoFail)
    }
  }

  if (!isMobile) {
    return null
  }

  return (
    <div className={stylesG.videoBackground}>
      {/* Video for larger screens */}
      <iframe
        src="https://player.vimeo.com/video/927154116?h=d68f296bed&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&controls=0"
        style={{ position: 'absolute', top: '-5%', left: 0, width: '100vw', height: '110%' }}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
      {/* <video autoPlay loop muted playsInline className={stylesG.video} onError={handleVideoError}>
                <source src='/assets/videos/bgVideoMobile.mp4' type='video/mp4' />
                <source src='/assets/videos/bgVideoMobile.webm' type='video/webm;codecs="vp8, vorbis"' />
            </video> */}

      {/* Fallback image for desktop */}
      {/* <div className={stylesG.desktopFallback}></div> */}

      {/* Image for mobile */}
      {/* <div className={stylesG.mobileBackground}></div> */}

      <div className={styles.container}>
        <AnimatePresence mode="wait">
          {activePage === 'home' && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className={styles.homeContainer}
            >
              <div className={styles.homeLogo}>
                <LedarLogoIconSmall color="#fff" />
              </div>
              <div className={styles.textWrapper}>
                <div className={styles.topBorder} />
                <div className={styles.title}>
                  {t(`homePage.title`)} <br /> {t(`homePage.project`)}
                </div>
                <div className={styles.subTitle}>{t(`homePage.subTitle`)}</div>
                <button className={styles.exploreButton} onClick={handleClick}>
                  {t(`homePage.explore`)}
                </button>
              </div>
              <div className={styles.menuWrapper}>
                <button
                  className={styles.menuButton}
                  onMouseEnter={() => handleMouseEnter('video')}
                  onMouseLeave={() => handleMouseLeave('video')}
                  onClick={() => setActivePage('video')}
                >
                  <div className={styles.menuItemWrapper}>
                    <VideoIcon showShadow={showShadow.video} />
                    <div className={styles.menuItem}>{t(`homePage.videos`)}</div>
                  </div>
                  <HomeArrowRightIcon showShadow={showShadow.arrow_video} />
                </button>
                <button
                  className={styles.menuButton}
                  onMouseEnter={() => handleMouseEnter('gallery')}
                  onMouseLeave={() => handleMouseLeave('gallery')}
                  onClick={() => setActivePage('gallery')}
                >
                  <div className={styles.menuItemWrapper}>
                    <GalleryIcon showShadow={showShadow.gallery} />
                    <div className={styles.menuItem}>{t(`homePage.gallery`)}</div>
                  </div>
                  <HomeArrowRightIcon showShadow={showShadow.arrow_gallery} />
                </button>
                <button
                  className={styles.menuButton}
                  onMouseEnter={() => handleMouseEnter('documents')}
                  onMouseLeave={() => handleMouseLeave('documents')}
                  onClick={() => setActivePage('documents')}
                >
                  <div className={styles.menuItemWrapper}>
                    <DocumentsIcon showShadow={showShadow.documents} />
                    <div className={styles.menuItem}>{t(`homePage.documents`)}</div>
                  </div>
                  <HomeArrowRightIcon showShadow={showShadow.arrow_documents} />
                </button>
              </div>
            </motion.div>
          )}
          {activePage === 'video' && (
            <Layout activeButton="video" onBackClick={goBackToHome} handlePageChange={handlePageChange}>
              <VideoPage />
            </Layout>
          )}
          {activePage === 'gallery' && (
            <Layout activeButton="gallery" onBackClick={goBackToHome} handlePageChange={handlePageChange}>
              <GalleryPage />
            </Layout>
          )}
          {activePage === 'documents' && (
            <Layout activeButton="documents" onBackClick={goBackToHome} handlePageChange={handlePageChange}>
              <DocumentPage />
            </Layout>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default MobileTabletHomePage
