import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import MapLocation from '../../components/Shared/MapLocation/MapLocation'
import NavigationTab from '../../components/Shared/NavigationTab/NavigationTab'
import { PageContext } from '../../contexts'
import styles from './LedarParkMasterPlan.module.css'
import HorizontalTaletMobileFilters from '../../components/Shared/HorizontalTaletMobileFilters/HorizontalTaletMobileFilters'
import { useTranslation } from 'react-i18next'
import Compass, { Position } from '../../components/Shared/Compass/Compass'
import { LedarLogoIconSmall, Radius } from '../../icons/icons'
import LocationPin from '../../components/Shared/LocationPin/LocationPin'
import {
    getMatrixTransformStyles,
    ReactZoomPanPinchRef,
    TransformComponent,
    TransformWrapper,
} from 'react-zoom-pan-pinch'
import { isMobile } from 'react-device-detect'

import Disclamer from '../../components/Shared/Disclamer/Disclamer'
import useScale from '../../hooks/useScale'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import MapWrapper from '../../components/Shared/_Map/MapWrapper'
import LedarParkMasterPlanTiles from './LedarParkMasterPlanTiles'
import LedarParkMasterPlanTiles2 from './LedarParkMasterPlanTiles2'

const LedarParkMasterPlan: React.FC = () => {
    return (
        <div className={styles.container}>
            <MapWrapper
                size={{ width: 5760, height: 3240, scaleAdd: 0.05, minScaleAdd: 0.05, maxScaleAdd: 1.2 }}
                center="auto"
            >
                {/* <LedarParkMasterPlanTiles /> */}
                <LedarParkMasterPlanTiles2 />
            </MapWrapper>
        </div>
    )
}

export default LedarParkMasterPlan
