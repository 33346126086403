// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_container__VyfVL {
  background: #00000080;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 12px;
  box-sizing: border-box;
  aspect-ratio: 1;
  cursor: pointer;
  transition: all 0.3s ease;
}

.Logo_container__VyfVL svg {
  width: 24px;
  height: auto;
}

.Logo_container__VyfVL:hover {
  background: linear-gradient(to right, #d27bfa, #3d55f8);
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/Logo/Logo.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,sBAAsB;EACtB,eAAe;EACf,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,uDAAuD;AACzD","sourcesContent":[".container {\n  background: #00000080;\n  border-radius: 12px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  padding: 0px 12px;\n  box-sizing: border-box;\n  aspect-ratio: 1;\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n\n.container svg {\n  width: 24px;\n  height: auto;\n}\n\n.container:hover {\n  background: linear-gradient(to right, #d27bfa, #3d55f8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Logo_container__VyfVL`
};
export default ___CSS_LOADER_EXPORT___;
