import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { propertiesApi } from '../services/properties'
import { propertiesApi2 } from '../services/properties2'
import { amenitiesApi } from '../services/amenities'

export const store = configureStore({
    reducer: {
        [propertiesApi.reducerPath]: propertiesApi.reducer,
        [propertiesApi2.reducerPath]: propertiesApi2.reducer,
        [amenitiesApi.reducerPath]: amenitiesApi.reducer,
    },

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(propertiesApi.middleware)
            .concat(propertiesApi2.middleware)
            .concat(amenitiesApi.middleware),
})

setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
