import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import styles from './BreadCrumb.module.css'
import { BreadCrumbArrowRightIcon, BreadCrumbArrowLeftIcon } from '../../../icons/icons'
import BackButton from '../BackButton/BackButton'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { PageContext } from '../../../contexts'
import { t } from 'i18next'

interface Breadcrumb {
  label: string
  path: string
}

const routes: Breadcrumb[] = [
  // { path: '/globe', label: 'Earth' },
  // { path: '/community', label: 'Community' },

  { path: '/country', label: 'Country' },
  { path: '/city', label: 'city' },
  { path: '/masterplan', label: 'residency' },
  { path: '/house-plan', label: 'unit' },
]

export default function Breadcrumbs() {
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const { pageTransitionTiming, displayedProperty } = useContext(PageContext)
  const location = useLocation()
  const navigate = useNavigate()
  const [breadcrumb, setBreadcrumb] = useState<Breadcrumb[]>([])

  useEffect(() => {
    const currentPath: string = location.pathname
    const updatedBreadcrumb: Breadcrumb[] = []
    const urlParams = new URLSearchParams(window.location.search)
    const propertyId = urlParams.get('property')

    for (const route of routes) {
      if (route.path === '/house-plan' && displayedProperty) {
        updatedBreadcrumb.push({
          path: route.path,
          label: propertyId
            ? t(`locations.${route.label}`, { id: displayedProperty.name })
            : t(`locations.${route.label}`),
        })
        break
      } else {
        updatedBreadcrumb.push({
          path: route.path,
          label: t(`locations.${route.label}`),
        })
      }
      if (route.path === currentPath) {
        break
      }
    }

    const breadcrumbTimeout = setTimeout(() => {
      setBreadcrumb(updatedBreadcrumb)
    }, pageTransitionTiming)

    return () => {
      clearTimeout(breadcrumbTimeout)
    }
  }, [location.pathname, pageTransitionTiming, displayedProperty, currentLanguage])

  const containerStyle: CSSProperties = {
    flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
  }

  const ArrowIcon = currentLanguage === 'ar' ? BreadCrumbArrowLeftIcon : BreadCrumbArrowRightIcon

  return (
    <nav className={styles.container} style={containerStyle}>
      <BackButton />
      <div className={styles.separator} />
      {breadcrumb?.map((page, index) => {
        if (page) {
          return (
            <React.Fragment key={index}>
              <div
                className={index === breadcrumb.length - 1 ? styles.activeText : styles.inactiveText}
                onClick={() => {
                  navigate(page.path)
                }}
              >
                {page.label}
              </div>
              {index < breadcrumb.length - 1 && (
                <div className={styles.arrowWrapper}>
                  <ArrowIcon />
                </div>
              )}
            </React.Fragment>
          )
        }
        return null
      })}
    </nav>
  )
}
