import React, { useEffect, useRef, useState, forwardRef } from 'react'
import {
    getMatrixTransformStyles,
    ReactZoomPanPinchRef,
    TransformComponent,
    TransformWrapper,
} from 'react-zoom-pan-pinch'

function setRootZoom(scale: number) {
    let root = document.getElementById('root')
    if (root && scale) {
        root.dataset.zoom = scale.toString()
    }
}

export default forwardRef(
    (
        {
            children,
            size,
            center,
        }: {
            children: React.ReactNode
            size: { width: number; height: number; scaleAdd: number; minScaleAdd: number; maxScaleAdd: number }
            center: string | { x: number; y: number }
        },
        ref
    ) => {
        const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null)

        const [scale, setScale] = useState<number | null>(null)

        React.useImperativeHandle(ref, () => ({
            zoomToElement: (id: string) => {
                transformWrapperRef.current?.zoomToElement(id)
                let refScale = transformWrapperRef.current?.instance.props.maxScale

                if (scale && refScale) {
                    setRootZoom(
                        ((refScale - (scale + size.scaleAdd)) / (scale + size.maxScaleAdd - (scale + size.scaleAdd))) *
                            4 +
                            1
                    )
                }
            },
            centerView: transformWrapperRef.current?.centerView,
            setTransform: transformWrapperRef.current?.setTransform,
            instance: transformWrapperRef.current?.instance,
        }))

        const calculateScale = () => {
            const contentWidth = size.width
            const contentHeight = size.height
            const viewportWidth = window.innerWidth
            const viewportHeight = window.innerHeight

            const widthScale = viewportWidth / contentWidth
            const heightScale = viewportHeight / contentHeight

            let newScale = Math.max(widthScale, heightScale)

            newScale = Math.round(newScale * 100) / 100

            setScale(newScale + size.scaleAdd)

            setTimeout(() => {
                if (transformWrapperRef && transformWrapperRef.current) {
                    // if (options.position === 'center') {
                    if (center === 'auto') {
                        transformWrapperRef.current?.centerView(newScale + size.scaleAdd)
                    } else if (center && typeof center === 'string') {
                        transformWrapperRef.current?.zoomToElement(center, newScale + size.scaleAdd)
                    } else if (center.hasOwnProperty('x') && center.hasOwnProperty('y')) {
                        //@ts-ignore
                        transformWrapperRef.current?.setTransform(center.x, center.y, newScale + size.scaleAdd)
                    }

                    setTimeout(() => {
                        let scale = transformWrapperRef.current?.instance.transformState.scale

                        if (scale) {
                            setRootZoom(
                                ((scale - (newScale + size.scaleAdd)) /
                                    (newScale + size.maxScaleAdd - (newScale + size.scaleAdd))) *
                                    4 +
                                    1
                            )
                        }
                    }, 100)
                }
            }, 100)
        }

        useEffect(() => {
            calculateScale()
            window.addEventListener('resize', calculateScale)

            return () => {
                window.removeEventListener('resize', calculateScale)
            }
        }, [])

        if (!scale) return null

        const handleZoom = (ref: ReactZoomPanPinchRef) => {
            if (scale) {
                setRootZoom(
                    Math.floor(
                        ((ref.state.scale - (scale + size.minScaleAdd)) /
                            (scale + size.maxScaleAdd - (scale + size.minScaleAdd))) *
                            4 +
                            1
                    )
                )
            }
        }

        return (
            <TransformWrapper
                ref={transformWrapperRef}
                minScale={scale + size.minScaleAdd}
                maxScale={scale + size.maxScaleAdd}
                initialScale={scale}
                limitToBounds={true}
                disablePadding={true}
                // customTransform={getMatrixTransformStyles}
                onZoom={ref => handleZoom(ref)}
            >
                {({ zoomToElement }) => (
                    <TransformComponent
                        wrapperStyle={{
                            width: '100%',
                            height: 'var(--vh)',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            pointerEvents: 'visible',
                            paddingRight: '100px',
                        }}
                    >
                        <div
                            style={{
                                width: `${size.width}px`,
                                height: `${size.height}px`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                pointerEvents: 'visible',
                            }}
                        >
                            {children}
                        </div>
                    </TransformComponent>
                )}
            </TransformWrapper>
        )
    }
)
