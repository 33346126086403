import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { FloorPlate } from '../types2/floorPlates'
// import { Property, PropertyResponse, PropertyModel } from '../types2/property'
// import { PropertyType } from '../types2/propertyType'

import { Property, PropertyCategory } from '../types2/property'

export const propertiesApi2 = createApi({
    reducerPath: 'propertiesApi2',
    baseQuery: fetchBaseQuery({
        // baseUrl: 'https://dkkkow0.etherial.cloud',
        baseUrl: 'https://virtualvisions.studio/ledar-crm/wp-json/wp/v2',
    }),

    endpoints: builder => ({
        // getProperties: builder.query<Property[], string>({
        //   query: (project) => `${project}/properties`,
        //   transformResponse: async (response: { data: Property[] }) => {
        //     return response.data;
        //   },
        // }),
        // getProperty: builder.query<Property, { project: string; id: number }>({
        //   query: ({ project, id }) => `${project}/properties/${id}`,
        //   transformResponse: async (response: { data: Property }) => {
        //     return response.data;
        //   },
        // }),
        // getStatus: builder.query({
        //   query: () => `status`,
        //   transformResponse: async (response: { data: Status[] }) => {
        //     return response.data;
        //   },
        // }),
        getProperty: builder.query<Property, number>({
            query: id => `/propertie/${id}`,
            transformResponse: async (response: Property) => {
                return response
            },
        }),
        getProperties: builder.query<Property[], void>({
            query: () => '/propertie?per_page=5000',
            transformResponse: async (response: Property[]) => {
                return response
            },
        }),

        getPropertiesCategories: builder.query<PropertyCategory[], void>({
            query: () => '/category-properties?per_page=100&order=desc',
            transformResponse: async (response: PropertyCategory[]) => {
                return response
            },
        }),

        getFloorPlates: builder.query<FloorPlate[], void>({
            query: () => '/floor-plates?per_page=100&order=desc',
            transformResponse: async (response: any[]) => {
                return response.map(floorPlate => {
                    return {
                        id: floorPlate.id,
                        name: floorPlate.name,
                        slug: floorPlate.slug,
                        image: floorPlate.acf.image,
                        front_svg_path: floorPlate.acf.front_svg_path,
                        back_svg_path: floorPlate.acf.back_svg_path,
                    }
                })
            },
        }),
        getFloorPlateById: builder.query<any, number>({
            query: id => `/floor-plates/${id}`,
            transformResponse: async (response: any) => {
                return response
            },
        }),
        // getPropertyTypes: builder.query<PropertyType[], void>({
        //     query: () => '/type-properties?per_page=100',
        //     transformResponse: async (response: any[]) => {
        //         return response.map(floorPlate => {
        //             return {
        //                 id: floorPlate.id,
        //                 name: floorPlate.name,
        //                 slug: floorPlate.slug,
        //                 type_color: floorPlate.acf.type_color,
        //                 parent: floorPlate.parent,
        //                 order: Number(floorPlate.acf.order ?? ('0' as string)),
        //                 gallery: floorPlate.acf.gallery,

        //                 property_mesurements: floorPlate.acf.property_mesurements,
        //             }
        //         })
        //     },
        // }),
        // getPropertyModels: builder.query<PropertyModel[], void>({
        //     query: () => '/model?per_page=100',
        //     transformResponse: async (response: any[]) => {
        //         return response.map(model => {
        //             return {
        //                 id: model.id,
        //                 name: model.name,
        //                 name_arabic: model.acf.name_arabic,
        //                 gallery: model.acf.gallery,
        //                 floors: model.acf.floors,
        //                 Mesures: model.acf.Mesures,
        //                 color: model.acf.color,
        //             }
        //         })
        //     },
        // }),
        getpropertyTypeById: builder.query<any, number>({
            query: id => `/type-properties/${id}`,
            transformResponse: async (response: any) => {
                return response
            },
        }),
    }),
})

export const {
    useLazyGetPropertyQuery,
    useGetPropertiesQuery,
    useGetFloorPlatesQuery,
    useLazyGetFloorPlateByIdQuery,
    useGetPropertiesCategoriesQuery,
    // useGetPropertyTypesQuery,
    // useLazyGetPropertyTypesQuery,
    useLazyGetpropertyTypeByIdQuery,
    // useGetPropertyModelsQuery,
} = propertiesApi2
