import React, { memo, useEffect, useMemo } from 'react'

let width = 9600
let height = 5400
let gridSize = [39, 22]
let link = 'mp3'

const preloadImages = (gridSize: number[], link: string) => {
    ;[...Array(gridSize[0])].forEach((_, index) => {
        ;[...Array(gridSize[1])].forEach((_, index2) => {
            const imagePreload = `/assets/tiles/${link}/tiles_${index + 1}_${index2 + 1}.webp`

            //@ts-ignore
            if (!window[imagePreload]) {
                const newImage = new Image()
                newImage.src = imagePreload
                //@ts-ignore
                window[imagePreload] = newImage
            }
        })
    })
}

export default memo(() => {
    useEffect(() => {
        preloadImages(gridSize, link)
    }, [])

    return (
        <>
            {[...Array(gridSize[0])].map((_, x) => {
                return [...Array(gridSize[1])].map((_, y) => {
                    const tileWidth = width / gridSize[0]
                    const tileHeight = height / gridSize[1]

                    return (
                        <>
                            <img
                                src={`/assets/tiles/${link}/tiles_${x + 1}_${y + 1}.webp`}
                                alt=""
                                key={`tile-${x}-${y}`}
                                style={{
                                    transition: 'all .5s ease-in-out',
                                    background: 'transparent',
                                    width: `${tileWidth / 3}px`,
                                    height: `${tileHeight / 3}px`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    pointerEvents: 'visible',
                                    position: 'absolute',
                                    top: `${(y * tileHeight) / 3}px`,
                                    left: `${(x * tileWidth) / 3}px`,
                                    // transform: 'scale(1.01)',
                                    transform: 'scale(1.02) ',
                                    // transform: 'scale(1.01)',
                                }}
                            />
                            {/* <div
                                style={{
                                    // background: 'blue',
                                    backgroundImage: `url(/assets/tiles/${link}/tiles_${x + 1}_${y + 1}.webp)`,
                                    position: 'absolute',
                                    color: 'white',
                                    fontSize: '5px',
                                    fontWeight: 'bold',

                                    width: `${tileWidth / 10}px`,
                                    height: `${tileHeight / 10}px`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    pointerEvents: 'visible',

                                    top: `${(y * tileHeight) / 10}px`,
                                    left: `${(x * tileWidth) / 10}px`,
                                }}
                            >
                                x: {x + 1} <br /> y: {y + 1} <br />
                                {tileWidth / 10}px <br /> {tileHeight / 10}px 
                            </div> */}
                        </>
                    )
                })
            })}
        </>
    )
})
