import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState, Fragment } from 'react'
import MapLocation from '../../components/Shared/MapLocation/MapLocation'
import NavigationTab from '../../components/Shared/NavigationTab/NavigationTab'
import { PageContext } from '../../contexts'
import styles from './LedarMasterPlan.module.css'
import HorizontalTaletMobileFilters from '../../components/Shared/HorizontalTaletMobileFilters/HorizontalTaletMobileFilters'
import { useTranslation } from 'react-i18next'
import Compass, { Position } from '../../components/Shared/Compass/Compass'
import { LedarLogoIconSmall, Radius } from '../../icons/icons'
import LocationPin from '../../components/Shared/LocationPin/LocationPin'
import {
    getMatrixTransformStyles,
    ReactZoomPanPinchRef,
    TransformComponent,
    TransformWrapper,
} from 'react-zoom-pan-pinch'
import { isMobile } from 'react-device-detect'

import Disclamer from '../../components/Shared/Disclamer/Disclamer'
import useScale from '../../hooks/useScale'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import MapWrapper from '../../components/Shared/_Map/MapWrapper'
import LedarMasterPlanTiles from './LedarMasterPlanTiles'
import LedarMasterPlanTiles2 from './LedarMasterPlanTiles2'
import LedarMasterPlanProperties from './LedarMasterPlanProperties'
import PropertyFilters from '../../components/Shared/PropertyFilters/PropertyFilters'
import ZoomToolbar from '../../components/Shared/ZoomToolbar/ZoomToolbar'
import { Property } from '../../types2/property'
import UnitInformations from '../../components/Shared/UnitInformations/UnitInformations.new'
import { useGetPropertiesQuery } from '../../services/properties2'

const LedarParkMasterPlan: React.FC = () => {
    const mapWrapperRef = useRef<any>(null)

    const [selectedProperty, setSelectedProperty] = useState<Property | null>(null)

    const [filterType, setFilterType] = useState<string[]>(['3', '4', '5'])
    const [filterPrice, setFilterPrice] = useState<[number, number]>([0, 10000000000])
    const [filterPlot, setFilterPlot] = useState<[number, number]>([0, 10000000000])

    const updateFilter = (filter: string, value: any) => {
        // setFilters({ ...filters, [filter]: value })
        if (filter === 'type') {
            setFilterType(value)
        } else if (filter === 'price') {
            setFilterPrice(value)
        } else if (filter === 'plot') {
            setFilterPlot(value)
        }
    }

    const applyFilters = (property: Property) => {
        let status = true

        if (property.acf.status !== 'active') {
            status = false
        }

        if (!filterType.some(type => property['category-properties'].map(t => t.toString()).includes(type))) {
            status = false
        }

        if (filterPrice[0] > Number(property.acf.moh_price) || filterPrice[1] < Number(property.acf.moh_price)) {
            status = false
        }

        if (filterPlot[0] > Number(property.acf.living_area) || filterPlot[1] < Number(property.acf.living_area)) {
            status = false
        }
        // if (filters.price[0] > property.price || filters.price[1] < property.price) {
        //     status = false
        // }

        return status
    }

    const mapZoomToElement = (id: string, scale: number) => {
        mapWrapperRef.current.zoomToElement(id, scale)
    }

    useEffect(() => {
        if (selectedProperty) {
            let root = document.getElementById('root')
            if (root) {
                root.dataset.selectedProperty = selectedProperty.id.toString()
            }
        }
    }, [selectedProperty])

    return (
        <div className={styles.container}>
            <MapWrapper
                ref={mapWrapperRef}
                size={{
                    width: 9600 / 3 - 30,
                    height: 5400 / 3 - 30,
                    scaleAdd: 0.05,
                    minScaleAdd: 0.05,
                    maxScaleAdd: 2,
                }}
                center="auto"
            >
                <LedarMasterPlanTiles2 />
                <LedarMasterPlanProperties
                    mapZoomToElement={mapZoomToElement}
                    setSelectedProperty={setSelectedProperty}
                    selectedProperty={selectedProperty}
                    applyFilters={applyFilters}
                />
            </MapWrapper>
            <LedarParkMasterPlanTools
                mapWrapperRef={mapWrapperRef}
                setSelectedProperty={setSelectedProperty}
                selectedProperty={selectedProperty}
                filterType={filterType}
                applyFilters={applyFilters}
                updateFilter={updateFilter}
            />
        </div>
    )
}

const LedarParkMasterPlanTools = ({
    mapWrapperRef,
    setSelectedProperty,
    selectedProperty,
    filterType,
    applyFilters,
    updateFilter,
}: {
    mapWrapperRef: any
    setSelectedProperty: (property: Property | null) => void
    selectedProperty: Property | null
    filterType: string[]
    applyFilters: (property: Property) => boolean
    updateFilter: (filter: string, value: any) => void
}) => {
    const { t, i18n } = useTranslation()
    const { activeButton } = useContext(PageContext)

    const currentLanguage = i18n.language

    return (
        <Fragment>
            {mapWrapperRef.current && mapWrapperRef.current.transformWrapper && (
                <ZoomToolbar transformWrapper={mapWrapperRef.current.transformWrapper} />
            )}

            <AnimatePresence>
                {activeButton === t(`nav.filters`) && (
                    <motion.div
                        variants={{
                            hidden: {
                                opacity: 0,
                            },
                            visible: {
                                opacity: 1,
                            },
                            exit: {
                                opacity: 0,
                            },
                        }}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.3 }}
                        className={styles.propertyFilterAnimatedContainer}
                        style={{
                            pointerEvents: 'none',
                            left: i18n.language === 'ar' ? 'unset' : '30px',
                            right: i18n.language === 'ar' ? '30px' : 'unset',
                        }}
                    >
                        <PropertyFilters
                            locationName={'location.pathname'}
                            filterType={filterType}
                            applyFilters={applyFilters}
                            updateFilter={updateFilter}
                        />
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {selectedProperty && (
                    <motion.div
                        variants={{
                            hidden: {
                                opacity: 0,
                                x: currentLanguage === 'ar' ? '-100%' : '100%',
                            },
                            visible: {
                                opacity: 1,
                                x: 0,
                            },
                            exit: {
                                opacity: 0,
                                x: currentLanguage === 'ar' ? '-100%' : '100%',
                            },
                        }}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.3 }}
                        className={styles.animatedContainer}
                        style={{
                            left: i18n.language === 'ar' ? '0' : 'unset',
                            right: i18n.language === 'ar' ? 'unset' : '0',
                        }}
                    >
                        <UnitInformations property={selectedProperty} setProperty={setSelectedProperty} />
                    </motion.div>
                )}
            </AnimatePresence>

            <NavigationTab locationName={t(`locations.residency`)} />
        </Fragment>
    )
}

export default LedarParkMasterPlan
