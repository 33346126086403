import { CSSProperties, useContext, useEffect, useState } from 'react'
import { CloseIcon, HelpIcon, InfoIcon } from '../../../icons/icons'
import RangeSlider from '../RangeSlider/RangeSlider'
import styles from './PropertyFilters.module.css'
import { MapFiltersType, PageContext } from '../../../contexts'
import { useTranslation } from 'react-i18next'
import MobileTabletRangeSlider from '../MobileTabletRangeSlider/MobileTabletRangeSlider'
import PropertyTypeModal from '../../Modal/PropertyTypeModal/PropertyTypeModal'
import { useGetPropertiesQuery, useGetPropertiesCategoriesQuery } from '../../../services/properties2'
import useConvertValues from '../../../hooks/useConvertValues'
import { isMobile } from 'react-device-detect'
import { Property } from '../../../types2/property'

import Draggable from 'react-draggable' // The default

interface Props {
    locationName: string
    filterType: string[]
    applyFilters: (property: Property) => boolean
    updateFilter: (filter: string, value: any) => void
}

const PropertyFilters: React.FC<Props> = ({ filterType, applyFilters, updateFilter }) => {
    const { t, i18n } = useTranslation()
    const currentLanguage = i18n.language
    // const { setActiveButton, setMapFilters, getMapFilter, resetMapFilters, polygones, displayedPolygones } =
    //     useContext(PageContext)
    const { data: properties } = useGetPropertiesQuery()
    const [modalSelectedPropertyTypeCard, setModalSelectedPropertyTypeCard] = useState<string | null>(null)
    const [showPropertyTypeModal, setShowPropertyTypeModal] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [sliderKey, setSliderKey] = useState(0)
    // const hoveredTypeConvertedArea = useConvertValues({ value: hoveredTypeData?.unit_size, option: 'sqm' })

    const { data: propertiesCategories } = useGetPropertiesCategoriesQuery()

    const closeModal = () => {
        setShowPropertyTypeModal(false)
        setModalSelectedPropertyTypeCard(null)
    }

    const handleReset = () => {
        setSliderKey(prevKey => prevKey + 1)
    }

    const handleClear = () => {
        // resetMapFilters()
        handleReset()
    }

    const handleClose = () => {
        // setActiveButton(null)
    }

    const wrapperStyle: CSSProperties & { [key: string]: any } = {
        flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
        right: currentLanguage === 'ar' ? '2%' : 'unset',
        left: currentLanguage === 'ar' ? 'unset' : '2%',
        '--direction': currentLanguage === 'ar' ? '100%' : '-100%',
    }

    const propertyTypeCardStyle: CSSProperties & { [key: string]: any } = {
        direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
        left: currentLanguage === 'ar' ? 'unset' : '325px',
        right: currentLanguage === 'ar' ? '325px' : 'unset',
        top: '150px',
    }

    const containerStyle: CSSProperties & { [key: string]: any } = {
        direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
    }

    const triggerTypeFilter = (type: string) => {
        if (filterType.includes(type)) {
            updateFilter(
                'type',
                filterType.filter(t => t !== type)
            )
        } else {
            updateFilter('type', [...filterType, type])
        }
    }

    if (!properties) {
        return <></>
    }

    return (
        // <Draggable allowAnyClick>
        <div className={styles.wrapper} style={wrapperStyle} draggable={true}>
            <div className={styles.container} style={containerStyle}>
                <div className={styles.header}>
                    <div>{t('propertyFilters.filters')}</div>
                    <button
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        className={styles.closeButton}
                        onClick={handleClose}
                    >
                        <CloseIcon color={isHovered ? '#000' : '#fff'} />
                    </button>
                </div>
                <div className={styles.propertyText}>
                    {t('propertyFilters.address', {
                        value: properties.filter(applyFilters).length,
                        total: properties.length,
                    })}
                </div>
                <div className={styles.columnWrapper}>
                    <div className={styles.rowWrapper}>
                        <div className={styles.label}>{t('propertyFilters.unitType')}</div>
                        <button className={styles.clearButton} onClick={handleClear}>
                            {t('propertyFilters.clearFilters')}
                        </button>
                    </div>
                    <div className={styles.propertyTypeWrapper}>
                        {propertiesCategories
                            ?.slice()
                            .sort((a, b) => {
                                return a.name.localeCompare(b.name)
                            })
                            .map(category => (
                                <div
                                    key={category.slug}
                                    style={{
                                        //@ts-ignore
                                        '--type-color': category.acf.color,
                                    }}
                                    data-active={filterType.includes(category.id.toString())}
                                    className={`${styles.propertStatusButton}`}
                                    onClick={() => triggerTypeFilter(category.id.toString())}
                                >
                                    <span>{category.name}</span>

                                    <div
                                        className={`${styles.propertyTypeCard} ${styles.appearAnimation}`}
                                        style={propertyTypeCardStyle}
                                    >
                                        <div className={styles.propertyTypeCardHeader}>
                                            <HelpIcon color="#D27BFA" />
                                            <div className={styles.propertyTypeCardHeaderTitle}>{category.name}</div>
                                        </div>
                                        <div className={styles.propertyTypeCardContent}>
                                            <div className={styles.contentRow}>
                                                <div>{t('propertyFilters.bedrooms')}</div>
                                                <div>{category?.acf.number_bedrooms}</div>
                                            </div>
                                            <div className={styles.contentRow}>
                                                <div>{t('propertyFilters.bathrooms')}</div>
                                                <div>{category?.acf.number_bathrooms}</div>
                                            </div>
                                            <div className={styles.contentRow}>
                                                <div>{t('propertyFilters.floor')}</div>
                                                <div>N/A</div>
                                            </div>
                                            <div className={styles.contentRow}>
                                                <div>{t('propertyFilters.totalArea')}</div>
                                                <div>
                                                    <span>N/A SQM</span>
                                                    {/* <span>{category?.acf.living_area} N/A SQM</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div className={styles.columnWrapper}>
                    <div className={styles.label}>{t('propertyFilters.priceRange')}</div>

                    {isMobile ? (
                        <MobileTabletRangeSlider
                            key={sliderKey}
                            availability={[0.1, 0.3, 0.5, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4]}
                            label="Currency"
                            options={['SAR', 'USD', 'EUR', 'GBP']}
                            minValue={
                                properties?.length > 0
                                    ? Math.min(...properties.map(property => Number(property.acf.moh_price)))
                                    : 0
                            }
                            maxValue={
                                properties?.length > 0
                                    ? Math.max(...properties.map(property => Number(property.acf.moh_price)))
                                    : 0
                            }
                            onUpdate={([min, max]) => {
                                updateFilter('price', [min, max])
                                // setFilters({ ...filters, price: [min, max] })
                            }}
                        />
                    ) : (
                        <RangeSlider
                            key={sliderKey}
                            availability={[0.1, 0.3, 0.5, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4]}
                            label="Currency"
                            options={['SAR', 'USD', 'EUR', 'GBP']}
                            minValue={
                                properties?.length > 0
                                    ? Math.min(...properties.map(property => Number(property.acf.moh_price)))
                                    : 0
                            }
                            maxValue={
                                properties?.length > 0
                                    ? Math.max(...properties.map(property => Number(property.acf.moh_price)))
                                    : 0
                            }
                            onUpdate={([min, max]) => {
                                updateFilter('price', [min, max])
                                // setFilters({ ...filters, price: [min, max] })
                            }}
                            // getMapFilter={getMapFilter}
                        />
                    )}
                </div>
                <div className={styles.columnWrapper}>
                    <div className={styles.label}>{t('propertyFilters.plotArea')}</div>

                    {isMobile ? (
                        <MobileTabletRangeSlider
                            key={sliderKey}
                            availability={[0.1, 0.3, 0.5, 0.7, 0.9, 1.6, 1.4, 1.7, 1.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4]}
                            label="Size Unit"
                            options={['sqm', 'sqft']}
                            minValue={
                                properties?.length > 0
                                    ? Math.min(...properties.map(property => Number(property.acf.living_area)))
                                    : 0
                            }
                            maxValue={
                                properties?.length > 0
                                    ? Math.max(...properties.map(property => Number(property.acf.living_area)))
                                    : 0
                            }
                            onUpdate={([min, max]) => {
                                updateFilter('plot', [min, max])
                                // setFilters({ ...filters, plot: [min, max] })
                            }}
                            // getMapFilter={getMapFilter}
                        />
                    ) : (
                        <RangeSlider
                            key={sliderKey}
                            availability={[0.1, 0.3, 0.5, 0.7, 0.9, 1.6, 1.4, 1.7, 1.9, 0.6, 0.4, 0.7, 0.9, 0.6, 0.4]}
                            label="Size Unit"
                            options={['sqm', 'sqft']}
                            minValue={
                                properties?.length > 0
                                    ? Math.min(...properties.map(property => Number(property.acf.living_area)))
                                    : 0
                            }
                            maxValue={
                                properties?.length > 0
                                    ? Math.max(...properties.map(property => Number(property.acf.living_area)))
                                    : 0
                            }
                            onUpdate={([min, max]) => {
                                updateFilter('plot', [min, max])
                            }}

                            // getMapFilter={getMapFilter}
                        />
                    )}
                </div>
            </div>
            {showPropertyTypeModal ? (
                <PropertyTypeModal selectedPropertyTypeCard={modalSelectedPropertyTypeCard} onClose={closeModal} />
            ) : null}
            {/* {selectedPropertyTypeCard && ( */}

            {/* )} */}
        </div>
        // </Draggable>
    )
}

export default PropertyFilters
