// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MasterPlanRect_masterPlanRect__2XEft {
    fill: var(--master-plan-rect-color);
    fill-opacity: 0.5;
    stroke: var(--master-plan-rect-color);
    stroke-width: 0.555556;
    stroke-linejoin: round;
    transition: fill-opacity 0.3s ease-in-out, stroke-opacity 0.3s ease-in-out;
    cursor: pointer;
}

.MasterPlanRect_masterPlanRect__2XEft:hover {
    fill-opacity: 0;
    stroke-opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/Shared/_Map/MasterPlanRect.module.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,iBAAiB;IACjB,qCAAqC;IACrC,sBAAsB;IACtB,sBAAsB;IACtB,0EAA0E;IAC1E,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".masterPlanRect {\n    fill: var(--master-plan-rect-color);\n    fill-opacity: 0.5;\n    stroke: var(--master-plan-rect-color);\n    stroke-width: 0.555556;\n    stroke-linejoin: round;\n    transition: fill-opacity 0.3s ease-in-out, stroke-opacity 0.3s ease-in-out;\n    cursor: pointer;\n}\n\n.masterPlanRect:hover {\n    fill-opacity: 0;\n    stroke-opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"masterPlanRect": `MasterPlanRect_masterPlanRect__2XEft`
};
export default ___CSS_LOADER_EXPORT___;
