import { SVGProps } from 'react'

const GroupedPathLabel = (
    props: SVGProps<SVGSVGElement> & {
        from: string
        to: string
        x: number
        y: number
        scale: number
        zoomToElement: (prop1: string, prop2: number) => void
    }
) => {
    return (
        <foreignObject
            className={'zoomLevel1   '}
            key={`grouped-text-${props.from}-${props.to}`}
            id={`grouped-text-${props.from}-${props.to}`}
            style={{ pointerEvents: 'none' }}
            width={500}
            height={500}
            x={props.x / props.scale}
            y={props.y / props.scale}
            transform={`scale(${props.scale})`}
        >
            <div
                onClick={() => {
                    props.zoomToElement(`grouped-text-${props.to}-${props.from}`, 20)
                }}
                style={{
                    background: 'rgba(0, 0, 0, 0.6)',
                    display: 'inline-block',
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                    borderRadius: 25,
                    zIndex: 1000,
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                }}
            >
                <span style={{ fontSize: '26px', color: 'white' }}>
                    {props.from} - {props.to}
                </span>
            </div>
        </foreignObject>
    )
}

export default GroupedPathLabel
