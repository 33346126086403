import { SVGProps, useState } from 'react'
import PropertyFilters from '../../components/Shared/PropertyFilters/PropertyFilters'
import { useGetPropertiesQuery } from '../../services/properties2'

import GroupedPathLabel from '../../components/Shared/_Map/GroupedPathLabel'
import { MasterPlanRect, MasterPlanRectLabel } from '../../components/Shared/_Map/MasterPlanRect'
import { Property } from '../../types2/property'

import styles from './LedarMasterPlan.module.css'

export default function LedarMasterPlanProperties({
    mapZoomToElement,
    setSelectedProperty,
    selectedProperty,
    applyFilters,
}: {
    mapZoomToElement: (id: string, scale: number) => void
    setSelectedProperty: (property: Property | null) => void
    selectedProperty: Property | null
    applyFilters: (property: Property) => boolean
}) {
    const { data: properties } = useGetPropertiesQuery()
    const [centers, setCenters] = useState<{ centerX: number; centerY: number; name: string; id: number }[]>([])

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
            }}
        >
            <svg style={{ position: 'absolute', top: 0.6, left: 0.2, width: '100%', height: '100%' }}>
                {properties
                    ?.filter(property => property.id !== selectedProperty?.id)
                    // .filter(property => )
                    .map(property => {
                        return (
                            <g
                                key={`${property.id}-${property.acf.land_number}`}
                                className={styles.masterPlanRectGroup}
                                data-active={applyFilters(property)}
                            >
                                <MasterPlanRect
                                    property={property}
                                    setCenter={center => {
                                        if (centers.find(c => c.id === center.id)) {
                                            return
                                        }
                                        setCenters(prevCenters => [...prevCenters, center])
                                    }}
                                    onClick={() => {
                                        setSelectedProperty(property)
                                        mapZoomToElement(`${property.id}-${property.acf.land_number}`, 5)
                                    }}
                                />
                            </g>
                        )
                    })}

                {[
                    [
                        [1, 42],
                        [600, 495],
                    ],
                    [
                        [44, 84],
                        [895, 724],
                    ],
                    [
                        [85, 126],
                        [895, 943],
                    ],
                    [
                        [311, 158],
                        [208, 800],
                    ],
                    [
                        [320, 349],
                        [200, 1210],
                    ],
                    [
                        [359, 380],
                        [500, 1270],
                    ],
                    [
                        [403, 418],
                        [1190, 1170],
                    ],
                    [
                        [419, 438],
                        [1650, 1170],
                    ],
                    [
                        [153, 178],
                        [1680, 950],
                    ],
                    [
                        [127, 152],
                        [1680, 724],
                    ],
                    [
                        [179, 193],
                        [2180, 724],
                    ],
                    [
                        [194, 212],
                        [2230, 950],
                    ],
                    [
                        [221, 242],
                        [2087, 1280],
                    ],
                    [
                        [243, 264],
                        [2384, 1280],
                    ],
                    [
                        [213, 220],
                        [1784, 1398],
                    ],
                    [
                        [293, 310],
                        [2664, 820],
                    ],
                    [
                        [265, 292],
                        [2694, 1220],
                    ],
                    [
                        [381, 402],
                        [808, 1270],
                    ],
                ].map(([[from, to], [x, y]]) => {
                    //@ts-ignore
                    return <GroupedPathLabel from={from} to={to} x={x} y={y} zoomToElement={() => {}} scale={0.7} />
                })}
                {properties?.map(property => {
                    let center = centers.find(c => c.id === property.id)
                    if (!center) {
                        return <></>
                    }
                    return (
                        <g
                            key={`center-${center.id}-group`}
                            className={styles.masterPlanRectGroup}
                            data-active={applyFilters(property)}
                        >
                            <MasterPlanRectLabel center={center} />
                        </g>
                    )
                })}
            </svg>
        </div>
    )
}
