import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import MapLocation from '../../components/Shared/MapLocation/MapLocation'
import NavigationTab from '../../components/Shared/NavigationTab/NavigationTab'
import { PageContext } from '../../contexts'
import styles from './CountryPage.module.css'
import HorizontalTaletMobileFilters from '../../components/Shared/HorizontalTaletMobileFilters/HorizontalTaletMobileFilters'
import { useTranslation } from 'react-i18next'
import Compass, { Position } from '../../components/Shared/Compass/Compass'
import { LedarLogoIconSmall, Radius } from '../../icons/icons'
import LocationPin from '../../components/Shared/LocationPin/LocationPin'
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { isMobile } from 'react-device-detect'

import Disclamer from '../../components/Shared/Disclamer/Disclamer'
import useScale from '../../hooks/useScale'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import {
    PinBuraydahAr,
    PinBuraydahEn,
    PinJeddahAr,
    PinJeddahEn,
    PinTabukAr,
    PinTabukEn,
    PinTaifAr,
    PinTaifEn,
} from './CountryPagePins'

export type LocationPinType = {
    name: string
    type: string
    icon: string
    position: {
        desktop: {
            left: string
            top: string
        }
    }
    localisationData: {
        distance: number
        duration: number
    }
    lineIcon: () => JSX.Element
    lineIconStyle: { width: string; top: string; left: string }
    reversed: boolean
}

const LocationPins: LocationPinType[] = []

const CloseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.6257 17.75C19.4131 17.75 19.235 17.6781 19.0915 17.5343C18.9479 17.3906 18.8761 17.2125 18.8761 17V6.99998C18.8761 6.78748 18.948 6.60935 19.0918 6.4656C19.2356 6.32187 19.4138 6.25 19.6264 6.25C19.839 6.25 20.0171 6.32187 20.1607 6.4656C20.3042 6.60935 20.376 6.78748 20.376 6.99998V17C20.376 17.2125 20.3041 17.3906 20.1603 17.5343C20.0165 17.6781 19.8383 17.75 19.6257 17.75ZM14.0126 12.75H2.31833C2.10583 12.75 1.92772 12.6781 1.78398 12.5343C1.64023 12.3904 1.56836 12.2122 1.56836 11.9997C1.56836 11.7871 1.64023 11.609 1.78398 11.4654C1.92772 11.3218 2.10583 11.25 2.31833 11.25H14.003L10.7049 7.91728C10.5664 7.77881 10.4962 7.60894 10.4943 7.40768C10.4924 7.20639 10.5658 7.03083 10.7145 6.881C10.8508 6.74367 11.0243 6.675 11.235 6.675C11.4457 6.675 11.6202 6.74423 11.7587 6.88268L16.2433 11.3673C16.3369 11.4609 16.4029 11.56 16.4414 11.6647C16.4799 11.7694 16.4991 11.8816 16.4991 12.0013C16.4991 12.1209 16.4799 12.2327 16.4414 12.3365C16.4029 12.4404 16.3369 12.5391 16.2433 12.6327L11.7491 17.1269C11.6122 17.2653 11.4412 17.3346 11.2359 17.3346C11.0306 17.3346 10.8536 17.2653 10.7049 17.1269C10.5498 16.9782 10.4738 16.8 10.477 16.5923C10.4802 16.3846 10.5562 16.2064 10.7049 16.0577L14.0126 12.75Z"
                fill="white"
            />
        </svg>
    )
}

const CardComponent = ({
    currentCard,
    setCurrentCard,
}: {
    currentCard: string | null
    setCurrentCard: Dispatch<SetStateAction<string | null>>
}) => {
    const { t, i18n } = useTranslation()
    const currentLanguage = i18n.language
    const navigate = useNavigate()

    const slideVariants = {
        hidden: {
            opacity: 0,
            // x: currentLanguage === 'ar' ? '100%' : '-100%',
        },
        visible: {
            opacity: 1,
            // x: 0,
        },
        // exit: {
        //     opacity: 0,
        //     x: currentLanguage === 'ar' ? '100%' : '-100%',
        // },
    }

    const isVisible = currentCard !== null

    let data = {
        image: '',
        logo: '',
        city: '',
        name: '',
        description: '',
        details: [3, 111556, 438],
        exploreButton: '',
    }

    if (currentCard === 'dar-alsadan' && currentLanguage === 'ar') {
        data = {
            image: '/assets/images/dar-alsadan-banner.jpeg',
            logo: '/assets/images/logo-daralsadan.png',
            city: 'جدة',
            name: 'دار السدَن',
            description:
                'دار السدَن هو أحد المشاريع التي تم تطويرها من قبل شركة ليدار للاستثمار بالتعاون مع الشركة الوطنية للإسكان. يمثل المشروع وجهة عصرية لأسلوب حياة متطور في الجزء الجنوبي من جدة، موجه للمستفيدين من خدمات الإسكان. يمتد المشروع على مساحة 111,556 مترًا مربعًا ويشمل 438 وحدة سكنية متاحة.',
            details: [3, 111556, 438],
            exploreButton: 'استكشاف الخريطة التفاعلية',
        }
    } else if (currentCard === 'dar-alsadan' && currentLanguage === 'en') {
        data = {
            image: '/assets/images/dar-alsadan-banner.jpeg',
            logo: '/assets/images/logo-daralsadan.png',
            city: 'Jeddah',
            name: 'Dar Alsadan',
            description:
                'Dar Al-Saden is one of the projects developed by Lidar Investment Company in collaboration with the National Housing Company. It represents a modern destination for a sophisticated lifestyle in the southern part of Jeddah, aimed at beneficiaries of housing services. The project covers an area of 111,556 square meters and includes 438 residential units available',
            details: [3, 111556, 438],
            exploreButton: 'Explore the interactive map',
        }
    } else if (currentCard === 'dar-alqadisya' && currentLanguage === 'ar') {
        data = {
            image: '/assets/images/dar-alqadisya-banner.jpeg',
            logo: '/assets/images/logo-daralqadisya.png',
            city: 'مشروع تبوك',
            name: 'دار القادسية',
            description:
                'دار القادسية المكان المُصمم ليلبي احتياجك بتعدد خياراته السكنية وتكامل مرافقه وخدماته، لتنعّم بالعيش في بيئة حيوية تتكامل تفاصيلها لتمنحك الاستقرار في منزلك الذي تبحث عنه يقع المشروع في شمال مدينة تبوك في حي القادسية على مساحة 144,960م2 ليضم 470 وحدة سكنية يتميز المشروع بموقعه الاستراتيجي وقربه من جامعة تبوك كما يبعد مطار الأمير سلطان بن عبد العزيز 15 دقيقة ويتميز المشروع بتوفير كامل المرافق والخدمات حيث سوف تعيش أنت وأسرتك جودة حياة متميزة',
            details: [3, 144960, 470],
            exploreButton: '',
        }
    } else if (currentCard === 'dar-alqadisya' && currentLanguage === 'en') {
        data = {
            image: '/assets/images/dar-alqadisya-banner.jpeg',
            logo: '/assets/images/logo-daralqadisya.png',
            city: 'Tabuk',
            name: 'Dar Al-Qadisiyya',
            description:
                'Dar Al-Qadisiyya is designed to meet your needs with a variety of residential options and complete facilities and services. It offers a vibrant, well-integrated environment to give you the stability you seek in your ideal home. The project is situated in the Al-Qadisiyya district in northern Tabuk City, covering an area of 144,960 m² and including 470 residential units. It boasts a strategic location near Tabuk University, just a 15-minute drive from Prince Sultan bin Abdulaziz Airport. The project ensures an exceptional quality of life for you and your family with all essential facilities and services provided.',
            details: [3, 144960, 470],
            exploreButton: '',
        }
    } else if (currentCard === 'dar-alwurud' && currentLanguage === 'ar') {
        data = {
            image: '/assets/images/dar-alwurud-banner.jpeg',
            logo: '/assets/images/logo-daralwurud.png',
            city: 'Ta’if',
            name: 'Dar Al-Wurud',
            description:
                'مواكَبةً للتطور العمراني الذي تشهده منطقة الطائف وتلبيةً لرغبة الباحثين عن مسكنٍ، يوفر مشروع دار الحوية كافة الخيارات السكنية بتصاميم مستوحاة من ماضي المدينة العريق، تحقق صورة السكن الذي تبحث عنه وسط جملة من المرافق والخدمات المتكاملة لتنعم بالعيش كما تتمنى',
            details: [3, 136319.5, 535],
            exploreButton: '',
        }
    } else if (currentCard === 'dar-alwurud' && currentLanguage === 'en') {
        data = {
            image: '/assets/images/dar-alwurud-banner.jpeg',
            logo: '/assets/images/logo-daralwurud.png',
            city: 'Ta’if',
            name: 'Dar Al-Wurud',
            description:
                'In line with the urban development in the Taif region and to meet the desires of home-seekers, the Dar Al-Wurud project offers a variety of residential options. The designs are inspired by the rich heritage of the city, reflecting the kind of home you’re looking for, alongside a comprehensive range of facilities and services to support a fulfilling lifestyle.',
            details: [3, 136319.5, 535],
            exploreButton: '',
        }
    } else if (currentCard === 'ledar-park' && currentLanguage === 'ar') {
        data = {
            image: '/assets/images/ledar-park-banner.jpeg',
            logo: '/assets/images/logo-ledarpark.png',
            city: 'بريدة',
            name: 'ليدار بارك',
            description:
                'مخطط ليدار بارك الممتد على مساحة تفوق 870,000م2 يمثل العنوان الجديد لأسلوب   حياة عصرية في منطقة القصيم شمال بريدة، حيث يحتضن بين أرجائه أراضٍ مخنلف المساحات ولاستخدمات تتيح لك حرية الأختيار لترسم تفاصيل حياتك بما يلأم تطلعاتك',
            details: [53, 87872, 870, 68, 69],
            exploreButton: '',
        }
    } else if (currentCard === 'ledar-park' && currentLanguage === 'en') {
        data = {
            image: '/assets/images/ledar-park-banner.jpeg',
            logo: '/assets/images/logo-ledarpark.png',
            city: 'Buraydah',
            name: 'Ledar Park',
            description:
                'Ledar Park, spanning over 870,000 m², represents the new address for a modern lifestyle in the Al-Qassim region, north of Buraydah. It offers a range of land plots of various sizes and uses, giving you the freedom to shape the details of your life to match your aspirations.',
            details: [53, 87872, 870, 68, 69],
            exploreButton: '',
        }
    }

    if (!isVisible) return null

    return (
        <>
            {!isMobile && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100px',
                        right: '450px',
                        padding: '12px',
                        borderRadius: '10px',
                        zIndex: 100,
                        backgroundColor: '#00000080',
                        cursor: 'pointer',
                    }}
                    onClick={() => setCurrentCard(null)}
                >
                    <CloseIcon />
                </div>
            )}
            <motion.div
                variants={slideVariants}
                initial="hidden"
                animate="visible"
                className={`${isMobile ? styles.cardContainerMobile : styles.cardContainer}`}
                style={{
                    direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                }}
            >
                {isMobile && (
                    <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            padding: '12px',
                            borderRadius: '10px',
                            zIndex: 100,
                            backgroundColor: '#00000080',
                        }}
                        onClick={() => setCurrentCard(null)}
                    >
                        <CloseIcon />
                    </div>
                )}

                <div className={styles.imageBanner}>
                    <img
                        src={data.image}
                        alt="Banner"
                        style={{
                            position: 'absolute',
                            width: isMobile ? 'calc(100% - 6px)' : 'calc(100% - 12px)',
                            objectFit: 'cover',
                            left: isMobile ? '3px' : '6px',
                            top: isMobile ? '3px' : '6px',
                            borderRadius: '15px',
                            height: '100%',
                        }}
                    />
                    {/* <div
                        className={styles.overlay}
                        style={{
                            position: 'absolute',
                            width: isMobile ? 'calc(100% - 6px)' : 'calc(100% - 12px)',
                            objectFit: 'cover',
                            left: isMobile ? '3px' : '6px',
                            top: isMobile ? '3px' : '6px',
                            borderRadius: '15px',
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#fff',
                            fontSize: '20px',
                            fontFamily: 'semi-bold',
                        }}
                    >
                        {currentLanguage === 'ar' ? 'عرض الصور' : 'View gallery'}
                    </div> */}
                </div>
                <div className={styles.cardContent} style={{ marginBottom: isMobile ? '250px' : '0px' }}>
                    <div className={styles.header}>
                        <img src={data.logo} alt="Placeholder Icon" className={styles.logo} />
                        <div>
                            <span
                                style={{
                                    fontSize: currentLanguage === 'ar' ? '18px' : '16px',
                                }}
                            >
                                {data.city}
                            </span>
                            <span>{data.name}</span>
                        </div>
                    </div>
                    <p
                        className={styles.description}
                        style={{
                            fontSize: currentLanguage === 'ar' ? '19px' : '15px',
                        }}
                    >
                        {data.description}
                    </p>
                    <div className={styles.details}>
                        {data.details[3] && data.details[4] ? (
                            <>
                                <div style={{ borderBottom: '1px solid #1110181A' }}>
                                    {currentLanguage === 'ar' && <span style={{ fontSize: '18px' }}>عدد البلوكات</span>}
                                    {currentLanguage === 'en' && (
                                        <span style={{ fontSize: '15px' }}>Number of blocks</span>
                                    )}
                                    <span>{data.details[0]}</span>
                                </div>
                                <div style={{ borderBottom: '1px solid #1110181A' }}>
                                    {currentLanguage === 'ar' && <span style={{ fontSize: '18px' }}>المساحة</span>}
                                    {currentLanguage === 'en' && <span style={{ fontSize: '15px' }}>Area</span>}
                                    <span>
                                        {data.details[1].toLocaleString('en-US')}{' '}
                                        {currentLanguage === 'en' ? 'sqm' : 'متر مربع'}
                                    </span>
                                </div>
                                <div style={{ borderBottom: data.details[3] ? '1px solid #1110181A' : 'none' }}>
                                    {currentLanguage === 'ar' && (
                                        <span style={{ fontSize: '18px' }}>عدد القطع السكنية</span>
                                    )}
                                    {currentLanguage === 'en' && (
                                        <span style={{ fontSize: '15px' }}>Number of residential plots</span>
                                    )}
                                    <span>{data.details[2]}</span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div style={{ borderBottom: '1px solid #1110181A' }}>
                                    {currentLanguage === 'ar' && <span style={{ fontSize: '18px' }}>عدد التصاميم</span>}
                                    {currentLanguage === 'en' && (
                                        <span style={{ fontSize: '15px' }}>Number of designs</span>
                                    )}
                                    <span>{data.details[0]}</span>
                                </div>
                                <div style={{ borderBottom: '1px solid #1110181A' }}>
                                    {currentLanguage === 'ar' && <span style={{ fontSize: '18px' }}>المساحة</span>}
                                    {currentLanguage === 'en' && <span style={{ fontSize: '15px' }}>Area</span>}
                                    <span>
                                        {data.details[1].toLocaleString('en-US')}{' '}
                                        {currentLanguage === 'en' ? 'sqm' : 'متر مربع'}
                                    </span>
                                </div>
                                <div style={{ borderBottom: data.details[3] ? '1px solid #1110181A' : 'none' }}>
                                    {currentLanguage === 'ar' && <span style={{ fontSize: '18px' }}>عدد الوحدات</span>}
                                    {currentLanguage === 'en' && (
                                        <span style={{ fontSize: '15px' }}>Number of units</span>
                                    )}
                                    <span>{data.details[2]}</span>
                                </div>
                            </>
                        )}

                        {data.details[3] && (
                            <div style={{ borderBottom: data.details[4] ? '1px solid #1110181A' : 'none' }}>
                                {currentLanguage === 'ar' && (
                                    <span style={{ fontSize: '18px' }}>عدد القطع التجارية</span>
                                )}
                                {currentLanguage === 'en' && (
                                    <span style={{ fontSize: '15px' }}>Number of commercial plots</span>
                                )}
                                <span>{data.details[3]}</span>
                            </div>
                        )}

                        {data.details[4] && (
                            <div style={{ borderBottom: data.details[5] ? '1px solid #1110181A' : 'none' }}>
                                {currentLanguage === 'ar' && (
                                    <span style={{ fontSize: '18px' }}>عدد العمائر السكنية</span>
                                )}
                                {currentLanguage === 'en' && (
                                    <span style={{ fontSize: '15px' }}>Number of residential buildings</span>
                                )}
                                <span>{data.details[4]}</span>
                            </div>
                        )}
                    </div>
                    {data.exploreButton && data.exploreButton != '' && (
                        <button
                            onClick={() => {
                                navigate('/city')
                            }}
                            className={styles.exploreButton}
                        >
                            {data.exploreButton}
                        </button>
                    )}
                </div>
            </motion.div>
        </>
    )
}

export default function CityPage() {
    const { t, i18n } = useTranslation()
    const { activeButton, activeFilterButtons } = useContext(PageContext)
    const [currentScale, setCurrentScale] = useState(0.195)
    const [clickedLocation, setClickedLocation] = useState<string | null>(null)
    const [activeLocations, setActiveLocations] = useState<LocationPinType[] | null>()
    const [hoveredLocation, setHoveredLocation] = useState<string | null>(null)

    const currentLanguage = i18n.language

    const [currentCard, setCurrentCard] = useState<string | null>(null)

    const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null)

    const handleLocationClick = (locationName: string) => {
        if (clickedLocation === locationName) {
            setClickedLocation(null)
        } else {
            setClickedLocation(locationName)
        }
    }

    const handleMouseOver = (element: HTMLElement, textElement: HTMLElement) => {
        element.style.opacity = '1'
        element.style.strokeWidth = '2'

        textElement.style.visibility = 'visible'
    }

    const handleMouseLeave = (element: HTMLElement, textElement: HTMLElement) => {
        element.style.opacity = '0.4'
        element.style.strokeWidth = '1'

        textElement.style.visibility = 'hidden'
    }

    useEffect(() => {
        if (activeButton === t(`nav.radius`)) {
            const radiuses = document.querySelectorAll('.radius')
            const radiusesText = document.querySelectorAll('.radiusText')

            if (radiuses.length === radiusesText.length) {
                for (let i = 0; i < radiuses.length; i++) {
                    ;(radiuses[i] as HTMLElement).addEventListener('mouseover', () =>
                        handleMouseOver(radiuses[i] as HTMLElement, radiusesText[i] as HTMLElement)
                    )
                    ;(radiuses[i] as HTMLElement).addEventListener('mouseleave', () =>
                        handleMouseLeave(radiuses[i] as HTMLElement, radiusesText[i] as HTMLElement)
                    )
                }
            }
        }

        return () => {
            const radiuses = document.querySelectorAll('.radius')
            const radiusesText = document.querySelectorAll('.radiusText')

            if (radiuses.length === radiusesText.length) {
                for (let i = 0; i < radiuses.length; i++) {
                    ;(radiuses[i] as HTMLElement).removeEventListener('mouseover', () =>
                        handleMouseOver(radiuses[i] as HTMLElement, radiusesText[i] as HTMLElement)
                    )
                    ;(radiuses[i] as HTMLElement).removeEventListener('mouseleave', () =>
                        handleMouseLeave(radiuses[i] as HTMLElement, radiusesText[i] as HTMLElement)
                    )
                }
            }
        }
    }, [activeButton, t])

    const disclamerPosition: Position = {
        mobile: {
            en: {
                top: '14%',
                left: '3%',
            },
            ar: {
                left: '3%',
                top: '7%',
            },
        },
        desktop: {
            en: {
                bottom: '5%',
                right: '2%',
            },
            ar: {
                bottom: '5%',
                left: '2%',
            },
        },
    }

    const compassPosition: Position = {
        mobile: {
            en: {
                top: '8%',
                left: '3%',
            },
            ar: {
                left: '3%',
                top: '2%',
            },
        },
        desktop: {
            en: {
                bottom: '5%',
                left: '2%',
            },
            ar: {
                bottom: '5%',
                right: '2%',
            },
        },
    }

    useEffect(() => {
        const setBodyHeight = () => {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
        }

        // Initial set
        setBodyHeight()

        // Update on resize
        window.addEventListener('resize', setBodyHeight)

        // Cleanup
        return () => {
            window.removeEventListener('resize', setBodyHeight)
        }
    }, [])

    useEffect(() => {
        const locations = LocationPins.filter(locationPin => activeFilterButtons[locationPin.type])
        setActiveLocations(locations)
    }, [activeFilterButtons])

    const handleZoom = (ref: ReactZoomPanPinchRef) => {
        setCurrentScale(ref.state.scale)
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--scale', `${currentScale}px`)
    }, [currentScale])

    const height = CSS.supports('height', '100svh') ? '100svh' : '100vh'

    const initialMapPosition = {
        desktop: {
            x: 0,
            y: 0,
        },
        mobile: {
            x: -1450,
            y: -900,
        },
    }

    let widthImage = 4096
    let heightImage = 2452

    const scale = useScale(
        {
            width: widthImage,
            height: heightImage,
            add: 0.05,
        },
        transformWrapperRef,
        'invisible-center'
        // isMobile ? { x: -600, y: 0 } : 'map-location'
        // { x: -600, y: 0 }
    )

    if (!scale) return null

    return (
        <div className={styles.container}>
            <TransformWrapper
                ref={transformWrapperRef}
                minScale={scale}
                maxScale={scale}
                initialScale={scale}
                // centerOnInit={true}
                centerZoomedOut={true}
                smooth
                limitToBounds={true}
                initialPositionX={isMobile ? initialMapPosition.mobile.x : initialMapPosition.desktop.x}
                initialPositionY={isMobile ? initialMapPosition.mobile.y : initialMapPosition.desktop.y}
                disablePadding={true}
                onZoom={ref => handleZoom(ref)}
            >
                <TransformComponent
                    contentStyle={
                        {
                            // willChange: 'transform',
                        }
                    }
                    wrapperStyle={{
                        width: '100%',
                        height: height,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        pointerEvents: 'visible',
                        paddingRight: '100px',
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url(/assets/images/backgrounds/${
                                currentLanguage === 'ar' ? 'SAAR' : 'SAEN'
                            }.webp)`,
                            width: `${widthImage}px`,
                            height: `${heightImage}px`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            pointerEvents: 'visible',
                        }}
                        onClick={() => setClickedLocation(null)}
                    >
                        <div
                            className={styles.overlay}
                            style={{
                                opacity: clickedLocation ? 0.7 : 0,
                            }}
                        />
                        {currentCard}

                        <div style={{ position: 'absolute', left: 1550, top: 820 }} id="invisible-center" />

                        <svg
                            width="293"
                            height="326"
                            viewBox="0 0 293 326"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ position: 'absolute', left: 962, top: 380 }}
                            className={`${styles.svg}${currentCard === 'dar-alqadisya' ? 'active' : ''}`}
                            id="map-location-dar-alqadisya"
                            onClick={() => {
                                setCurrentCard('dar-alqadisya')
                                transformWrapperRef.current?.zoomToElement('invisible-center', scale)
                            }}
                        >
                            {currentLanguage === 'ar' ? <PinTabukAr /> : <PinTabukEn />}
                        </svg>

                        <svg
                            width="293"
                            height="326"
                            viewBox="0 0 293 326"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ position: 'absolute', left: 1280, top: 820 }}
                            className={`${styles.svg}${currentCard === 'dar-alwurud' ? 'active' : ''}`}
                            id="map-location-dar-alwurud"
                            onClick={() => {
                                setCurrentCard('dar-alwurud')
                                transformWrapperRef.current?.zoomToElement('invisible-center', scale)
                            }}
                        >
                            {currentLanguage === 'ar' ? <PinTaifAr /> : <PinTaifEn />}
                        </svg>

                        <svg
                            width="360"
                            height="326"
                            viewBox="0 0 360 326"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ position: 'absolute', left: 1790, top: 621 }}
                            className={`${styles.svg}${currentCard === 'ledar-park' ? 'active' : ''}`}
                            id="map-location-ledar-park"
                            onClick={() => {
                                setCurrentCard('ledar-park')
                                transformWrapperRef.current?.zoomToElement('invisible-center', scale)
                            }}
                        >
                            {currentLanguage === 'ar' ? <PinBuraydahAr /> : <PinBuraydahEn />}
                        </svg>

                        <svg
                            width="299"
                            height="319"
                            viewBox="0 0 299 319"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ position: 'absolute', left: 1245, top: 1275 }}
                            className={`${styles.svg}${currentCard === 'dar-alsadan' ? 'active' : ''}`}
                            onClick={() => {
                                setCurrentCard('dar-alsadan')
                                transformWrapperRef.current?.zoomToElement('invisible-center', scale)
                            }}
                            id="map-location-dar-alsadan"
                        >
                            {currentLanguage === 'ar' ? <PinJeddahAr /> : <PinJeddahEn />}
                        </svg>
                    </div>
                </TransformComponent>
                <NavigationTab locationName={t(`locations.city`)} />
                <HorizontalTaletMobileFilters />
                <Compass position={compassPosition} />
                <Disclamer position={disclamerPosition} />

                <CardComponent currentCard={currentCard} setCurrentCard={setCurrentCard} />
            </TransformWrapper>
        </div>
    )
}
