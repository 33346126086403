import { CSSProperties, useContext, useEffect, useState } from 'react'
import Breadcrumbs from '../BreadCrumb/BreadCrumbs'
import CaptionButton from '../CaptionButton/CaptionButton'
import Logo from '../Logo/Logo'
import VRModeButton from '../VRModeButton/VRModeButton'
import styles from './TopBar.module.css'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { PageContext } from '../../../contexts'

interface Props {
    isVRMode?: boolean
}

const TopBar: React.FC<Props> = ({ isVRMode }) => {
    const { i18n } = useTranslation()
    const currentLanguage = i18n.language
    const { pageTransitionTiming } = useContext(PageContext)
    const location = useLocation()
    const [displayedContent, setDisplayedContent] = useState<string | null>(null)

    const containerStyle: CSSProperties = {
        flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
        left: currentLanguage === 'ar' ? 'unset' : '2vw',
        right: currentLanguage === 'ar' ? '4vw' : 'unset',
    }

    useEffect(() => {
        const topbarTimeout = setTimeout(() => {
            setDisplayedContent(location.pathname)
        }, pageTransitionTiming)

        return () => clearTimeout(topbarTimeout)
    }, [location.pathname, pageTransitionTiming])

    if (displayedContent === '/') {
        return null
    }

    return (
        <div>
            <div
                className={styles.container}
                style={{
                    flexDirection: 'row-reverse',
                    left: 'unset',
                    right: '1vw',
                }}
            >
                <Logo />
            </div>
            <div className={styles.container} style={containerStyle}>
                {displayedContent !== '/globe' ? <Breadcrumbs /> : null}

                {displayedContent === '/house-plan' ? <VRModeButton /> : null}
                {displayedContent === '/house-plan' && !isVRMode ? <CaptionButton /> : null}
            </div>
        </div>
    )
}

export default TopBar
