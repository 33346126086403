import React, { CSSProperties, useContext, useEffect, useState } from 'react'
import styles from './HousePlanOptions.module.css'
import { PageContext } from '../../../contexts'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { CameraFloorType } from '../../../pages/HousePlanPage/HousePlanPage'

interface Props {
  selectedOption: string | null
  onSelectOption: (filter: string | null) => void
}

const HousePlanOptions: React.FC<Props> = ({ selectedOption, onSelectOption }) => {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const { isVRMode, toggleVRMode, toggleCaptions } = useContext(PageContext)

  useEffect(() => {
    onSelectOption(t(`button.info`))
  }, [onSelectOption, t])

  const [showFloorCard, setShowFloorCard] = useState(false)

  const handle3DButtonClick = () => {
    toggleVRMode()
    onSelectOption('3D')
  }

  const handleFloorButtonClick = () => {
    if (selectedOption === 'Floor') {
      onSelectOption(null)
    } else {
      setShowFloorCard(!showFloorCard)
      onSelectOption('Floor')
    }
  }

  // eslint-disable-next-line
  const handleFloorSelection = (floor: string) => {
    onSelectOption('3D')
  }

  const handleInfoSelection = () => {
    onSelectOption(t(`button.info`))
    toggleCaptions(true)
  }

  const containerStyle: CSSProperties = {
    direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
  }

  if (!isMobile) {
    return null
  }

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.sliderContainer}>
        <ThreeDButton onClick={handle3DButtonClick} isActive={selectedOption === '3D'} isVRMode={isVRMode} />
        <div>
          <FloorButton onClick={handleFloorButtonClick} isActive={selectedOption === 'Floor'} />
          {selectedOption === 'Floor' ? <FloorCard /> : null}
        </div>
        {isVRMode ? (
          <AdditionalButtons />
        ) : (
          <InfoButton onClick={handleInfoSelection} isActive={selectedOption === t(`button.info`)} />
        )}
      </div>
    </div>
  )
}

const ThreeDButton: React.FC<{
  onClick: () => void
  isActive: boolean
  isVRMode: boolean
}> = ({ onClick, isActive, isVRMode }) => (
  <button onClick={onClick} className={`${styles.button} ${isActive ? styles.selected : ''}`}>
    {isVRMode ? 'Plan' : '3D'}
  </button>
)

const FloorButton: React.FC<{ onClick: () => void; isActive: boolean }> = ({ onClick, isActive }) => (
  <button onClick={onClick} className={`${styles.button} ${isActive ? styles.selected : ''}`}>
    GF
  </button>
)

const FloorCard: React.FC = () => {
  const { t, i18n } = useTranslation()
  const currentLanguage = i18n.language
  const direction = currentLanguage === 'ar' ? 'rtl' : 'ltr'
  const { setCurrentFloor, currentFloor } = useContext(PageContext)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const isMobile = windowWidth <= 576
  const positionValue = isMobile ? '15%' : '9%'

  const cardStyle: CSSProperties = {
    direction,
    right: currentLanguage === 'ar' ? positionValue : 'unset',
    left: currentLanguage === 'ar' ? 'unset' : positionValue,
  }

  const buttons = [
    { label: t(`button.groundFloor`), key: 0 },
    { label: t(`button.firstFloor`), key: 1 },
    { label: t(`button.roofFloor`), key: 2 },
  ]

  return (
    <div className={styles.card} style={cardStyle}>
      {buttons.reverse().map(floor => (
        <div
          key={floor.label}
          onClick={() => {
            setCurrentFloor(floor.key)
          }}
          className={`${styles.floor} ${currentFloor === floor.key ? styles.selectedFloor : ''}`}
        >
          {floor.label}
        </div>
      ))}
    </div>
  )
}

const floorCameras: CameraFloorType[] = [
  {
    type: 1,
    floors: [
      {
        number: 0,
        cameras: [
          { alias: 'TV Area', url: 'Corona Camera006' },
          { alias: 'Prep. Kitchen', url: 'Corona Camera011' },
          { alias: 'Dining Room', url: 'Corona Camera005' },
          { alias: 'Majless', url: 'Corona Camera004' },
        ],
      },
      {
        number: 1,
        cameras: [
          { alias: 'Master Bedroom', url: 'Corona Camera024' },
          { alias: 'Junior Room 1', url: 'Corona Camera019' },
          { alias: 'Junior Room 2', url: 'Corona Camera021' },
          { alias: 'Junior Room 3', url: 'Corona Camera028' },
        ],
      },
      {
        number: 2,
        cameras: [
          { alias: 'Maid Room', url: 'Corona Camera034' },
          { alias: 'Bath Room', url: 'Corona Camera035' },
        ],
      },
    ],
  },
  {
    type: 2,
    floors: [
      {
        number: 0,
        cameras: [
          { alias: 'TV Area', url: 'Corona Camera032' },
          { alias: 'Prep. Kitchen', url: 'Corona Camera038' },
          { alias: 'Dining Room', url: 'Corona Camera030' },
          { alias: 'Majless', url: 'Corona Camera028' },
        ],
      },
      {
        number: 1,
        cameras: [
          { alias: 'Master Bedroom', url: 'Corona Camera055' },
          { alias: 'Junior Room 1', url: 'Corona Camera052' },
          { alias: 'Junior Room 2', url: 'Corona Camera048' },
          { alias: 'Junior Room 3', url: 'Corona Camera046' },
        ],
      },
      {
        number: 2,
        cameras: [
          { alias: 'Maid Room', url: 'Corona Camera063' },
          { alias: 'Bath Room', url: 'Corona Camera064' },
        ],
      },
    ],
  },
  {
    type: 3,
    floors: [
      {
        number: 0,
        cameras: [
          { alias: 'TV Area', url: 'Corona Camera032' },
          { alias: 'Prep. Kitchen', url: 'Corona Camera038' },
          { alias: 'Dining Room', url: 'Corona Camera030' },
          { alias: 'Majless', url: 'Corona Camera028' },
        ],
      },
      {
        number: 1,
        cameras: [
          { alias: 'Master Bedroom', url: 'Corona Camera055' },
          { alias: 'Junior Room 1', url: 'Corona Camera052' },
          { alias: 'Junior Room 2', url: 'Corona Camera048' },
          { alias: 'Junior Room 3', url: 'Corona Camera046' },
        ],
      },
      {
        number: 2,
        cameras: [
          { alias: 'Maid Room', url: 'Corona Camera063' },
          { alias: 'Bath Room', url: 'Corona Camera064' },
          { alias: 'Multipurpose Room', url: 'Corona Camera062' },
        ],
      },
    ],
  },
]

const AdditionalButtons: React.FC = () => {
  const { t } = useTranslation()
  const { isVRMode, iframeVR, currentFloor, displayedProperty } = useContext(PageContext)
  const [selectedButton, setSelectedButton] = useState('')
  const [model, setModel] = useState<number | null>(null)
  const [cameraButtons, setCameraButtons] = useState<any[]>([])

  useEffect(() => {
    if (displayedProperty) {
      const model = displayedProperty.property_type_id

      setModel(model)
    }
  }, [displayedProperty])

  useEffect(() => {
    if (model !== null && currentFloor !== null) {
      const cameras = floorCameras
        .find(camera => camera.type === model)
        ?.floors.find(floor => floor.number === currentFloor)?.cameras

      if (cameras) {
        setCameraButtons(cameras)
      }
    }
  }, [model, currentFloor])

  const handleButtonClick = (url: string) => {
    iframeVR.current?.contentWindow?.postMessage({ v1: 'LayamaTeleportCamera', v2: url }, '*')
    setSelectedButton(url)
  }

  useEffect(() => {
    //@ts-ignore
    const getCurrentPositionFromVR = e => {
      if (e.data && e.data.key === 'currentCamera') {
        let currentCamera = e.data.value
        setSelectedButton(currentCamera)
      }
    }

    window.addEventListener('message', getCurrentPositionFromVR)

    return () => {
      window.removeEventListener('message', getCurrentPositionFromVR)
    }
  }, [])

  if (!isVRMode) {
    return null
  }

  return (
    <>
      {cameraButtons.map((camera, index) => (
        <button
          key={index}
          className={selectedButton === camera.url ? styles.selectedButton : styles.button}
          onClick={() => handleButtonClick(camera.url)}
        >
          {camera.alias}
        </button>
      ))}
    </>
  )
}

const InfoButton: React.FC<{ onClick: () => void; isActive: boolean }> = ({ onClick, isActive }) => {
  const { t } = useTranslation()
  return (
    <button onClick={onClick} className={`${styles.button} ${isActive ? styles.selected : ''}`}>
      {t(`button.info`)}
    </button>
  )
}

export default HousePlanOptions
