// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zoomLevel1,
.zoomLevel2,
.zoomLevel3,
.zoomLevel4,
.zoomLevel5 {
    display: none;
}

#root[data-zoom='1'] .zoomLevel1 {
    display: block;
}
#root[data-zoom='2'] .zoomLevel2 {
    display: block;
}
#root[data-zoom='3'] .zoomLevel3 {
    display: block;
}
#root[data-zoom='4'] .zoomLevel4 {
    display: block;
}

#root[data-zoom='5'] .zoomLevel5 {
    display: block;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;IAKI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".zoomLevel1,\n.zoomLevel2,\n.zoomLevel3,\n.zoomLevel4,\n.zoomLevel5 {\n    display: none;\n}\n\n#root[data-zoom='1'] .zoomLevel1 {\n    display: block;\n}\n#root[data-zoom='2'] .zoomLevel2 {\n    display: block;\n}\n#root[data-zoom='3'] .zoomLevel3 {\n    display: block;\n}\n#root[data-zoom='4'] .zoomLevel4 {\n    display: block;\n}\n\n#root[data-zoom='5'] .zoomLevel5 {\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
