import React, { useEffect, useState } from 'react'

export default function useScale(
  size: { width: number; height: number; add: number },
  transformWrapperRef: React.RefObject<any>,
  // autoCenter: boolean = true
  center: 'auto' | { x: number; y: number } | string = 'auto'
  // options: { position: 'center' | null | { x: number; y: number } } = { position: 'center' }
) {
  const [scale, setScale] = useState<number | null>(null)

  const calculateScale = () => {
    const contentWidth = size.width
    const contentHeight = size.height
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight

    const widthScale = viewportWidth / contentWidth
    const heightScale = viewportHeight / contentHeight

    let newScale = Math.max(widthScale, heightScale)

    newScale = Math.round(newScale * 100) / 100

    setScale(newScale + size.add)

    setTimeout(() => {
      if (transformWrapperRef && transformWrapperRef.current) {
        // if (options.position === 'center') {
        if (center === 'auto') {
          transformWrapperRef.current?.centerView(newScale + size.add)
        } else if (center && typeof center === 'string') {
          transformWrapperRef.current?.zoomToElement(center, newScale + size.add)
        } else if (center.hasOwnProperty('x') && center.hasOwnProperty('y')) {
          //@ts-ignore
          transformWrapperRef.current?.setTransform(center.x, center.y, newScale + size.add)
        }

        // } else if (options.position && 'x' in options.position && 'y' in options.position) {
        // transformWrapperRef.current?.centerView(newScale + 0.05)
        // }
      }
    }, 100)
  }

  useEffect(() => {
    calculateScale()
    window.addEventListener('resize', calculateScale)

    return () => {
      window.removeEventListener('resize', calculateScale)
    }
  }, [])

  return scale
}
