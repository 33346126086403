import { SVGProps, useEffect, useState } from 'react'

import { Property } from '../../../types2/property'

import styles from './MasterPlanRect.module.css'
import { useGetPropertiesCategoriesQuery } from '../../../services/properties2'
import { useNavigate, useSearchParams } from 'react-router-dom'

function calculateCenter(ref: SVGPathElement) {
    if (ref) {
        const bbox = ref.getBBox()
        const centerX = bbox.x + bbox.width / 2
        // const centerY = bbox.y + bbox.height
        //I want centerY to be at the top of the rect
        const centerY = bbox.y + bbox.height / 2
        return { centerX, centerY }
    }
    return { centerX: 0, centerY: 0 } // Fallback if the path is not found
}

export const MasterPlanRectLabel = (
    props: SVGProps<SVGSVGElement> & { center: { centerX: number; centerY: number; name: string; id: number } }
) => {
    let width = 30
    let height = 15

    let x = 0
    let y = height / 2

    return (
        <g className="zoomLevel2 zoomLevel3 zoomLevel4 zoomLevel5" key={'center' + props.center.id}>
            <rect
                x={props.center.centerX - x - width / 2}
                y={props.center.centerY - y - height / 2}
                width={width}
                height={height}
                rx="5"
                ry="5"
                fill="rgba(0, 0, 0, 0.6)"
            />
            {/* <polygon
                points={` 
                    ${props.center.centerX - x - 3}, ${props.center.centerY - y + (width / 2 - 2) / 2}
                    ${props.center.centerX - x + 3}, ${props.center.centerY - y + (width / 2 - 2) / 2}
                    ${props.center.centerX - x}, ${props.center.centerY - y + (width / 2 - 2) / 2 + 5}
                `}
                fill="rgba(0, 0, 0, 0.6)"
            /> */}
            <text
                x={props.center.centerX - x}
                y={props.center.centerY - y + 1}
                dominantBaseline="middle"
                textAnchor="middle"
                fill="#FFFFFF"
                fontSize="9"
                // fontFamily="Arial, sans-serif"
            >
                {props.center.name}
            </text>
        </g>
    )
}

export const MasterPlanRect = ({
    property,
    setCenter,
    onClick,
}: {
    property: Property
    setCenter: (center: { centerX: number; centerY: number; name: string; id: number }) => void
    onClick: () => void
}) => {
    const [internalCenter, setInternalCenter] = useState({ centerX: 0, centerY: 0, name: '', id: 0 })
    const { data: categories } = useGetPropertiesCategoriesQuery()

    if (!property || !categories) {
        return <></>
    }

    let category = categories.find(cat => property['category-properties'][0] == cat.id)

    if (!category) {
        return <></>
    }

    return (
        <>
            <path
                style={{
                    //@ts-ignore
                    '--master-plan-rect-color': category?.acf.color,
                }}
                id={`${property.id}-${property.acf.land_number}`}
                className={styles.masterPlanRect}
                ref={ref => {
                    if (ref && (!internalCenter.centerX || !internalCenter.centerY)) {
                        const newCenter = calculateCenter(ref)
                        if (
                            newCenter.centerX !== internalCenter.centerX ||
                            newCenter.centerY !== internalCenter.centerY
                        ) {
                            setInternalCenter({ ...newCenter, name: property.acf.land_number, id: property.id })
                            setCenter({ ...newCenter, name: property.acf.land_number, id: property.id })
                        }
                    }
                }}
                d={property.acf.svg_masterplan_unit}
                onClick={() => {
                    onClick()
                }}
            />
        </>
    )
}
