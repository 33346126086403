import { CSSProperties, useContext, useEffect, useRef, useState } from 'react'
import { PageContext } from '../../../contexts'
import { CloseIcon } from '../../../icons/icons'
import styles from './UnitInformations.module.css'
import ImageCarousel from '../ImageCarousel/ImageCarousel'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useConvertValues from '../../../hooks/useConvertValues'
import { useGetStatusQuery } from '../../../services/properties'
import { Property } from '../../../types2/property'
import { useGetPropertiesCategoriesQuery } from '../../../services/properties2'

export default function UnitInformations({
    property,
    setProperty,
}: {
    property: Property
    setProperty: (property: Property | null) => void
}) {
    const { data: categories } = useGetPropertiesCategoriesQuery()

    const { resetButtons, setActiveButton, toggleVRMode } = useContext(PageContext)
    const { data: statuses } = useGetStatusQuery({})
    const { t, i18n } = useTranslation()
    const currentLanguage = i18n.language
    const navigate = useNavigate()
    const [selectedAreaUnit, setSelectedAreaUnit] = useState('SQM')
    const [selectedCurrency, setSelectedCurrency] = useState('SAR')
    const [isHovered, setIsHovered] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const convertedArea = useConvertValues({ value: Number(property?.acf.unit_size), option: selectedAreaUnit })
    const convertedPrice = useConvertValues({ value: Number(property?.acf.moh_price), option: selectedCurrency })
    const [currentStatus, setCurrentStatus] = useState('available')

    if (!categories) {
        return <></>
    }

    let category = categories.find(cat => property['category-properties'][0] == cat.id)

    const handleRegisterClick = () => {
        setActiveButton('register')
    }

    const handleClose = () => {
        setProperty(null)
    }

    const handleFloorPlanClick = () => {
        navigate(`/house-plan?property=${property?.id}`)
        resetButtons()
        // toggleVRMode();
    }

    const handleVRClick = () => {
        navigate(`/house-plan?property=${property?.id}`)
        resetButtons()
        toggleVRMode()
    }

    const containerStyle: CSSProperties & { [key: string]: any } = {
        right: currentLanguage === 'ar' ? 'unset' : '0%',
        left: currentLanguage === 'ar' ? '0%' : 'unset',
        '--direction': currentLanguage === 'ar' ? '-100%' : '100%',
        direction: currentLanguage === 'ar' ? 'rtl' : 'ltr',
    }

    const titleWrapperStyle: CSSProperties & { [key: string]: any } = {
        marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
        marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
    }

    const descriptionStyle: CSSProperties & { [key: string]: any } = {
        marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
        marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
    }

    const tableStyle: CSSProperties & { [key: string]: any } = {
        marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
        marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
    }

    const priceWrapperStyle: CSSProperties & { [key: string]: any } = {
        marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
        marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
    }

    const buttonWrapperStyle: CSSProperties & { [key: string]: any } = {
        marginLeft: currentLanguage === 'ar' ? 'unset' : '7%',
        marginRight: currentLanguage === 'ar' ? '7%' : 'unset',
    }

    const closeButtonStyle: CSSProperties = {
        right: currentLanguage === 'ar' ? 'unset' : '3%',
        left: currentLanguage === 'ar' ? '3%' : 'unset',
    }

    if (isMobile) {
        return null
    }

    return (
        <div className={styles.container} style={containerStyle} ref={containerRef}>
            <button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={styles.closeButton}
                style={closeButtonStyle}
                onClick={handleClose}
            >
                <CloseIcon color={isHovered ? '#000' : '#fff'} />
            </button>
            <div className={styles.titleWrapper} style={titleWrapperStyle}>
                <div className={[styles.availability, styles[currentStatus ?? 'available']].join(' ')}>
                    {t(`propertyInformations.${currentStatus ?? 'available'}`)}
                </div>
                <div className={styles.title}>{t(`propertyInformations.unit`, { id: property?.acf.land_number })}</div>
            </div>
            <p className={styles.description} style={descriptionStyle}>
                {t(`propertyInformations.description`)}
            </p>
            <ImageCarousel
                images={[
                    '/assets/images/carrousel-image1.jpeg',
                    '/assets/images/carrousel-image2.jpg',
                    '/assets/images/carrousel-image3.jpg',
                    '/assets/images/carrousel-image4.jpg',
                    '/assets/images/carrousel-image5.jpg',
                    '/assets/images/carrousel-image6.jpg',
                    '/assets/images/carrousel-image7.jpg',
                ]}
            />
            <div className={styles.table} style={tableStyle}>
                <div className={styles.row}>
                    <span>{t(`propertyInformations.bedrooms`)}</span>
                    <span>{property?.acf.number_bedrooms}</span>
                </div>
                <div className={styles.row}>
                    <span>{t(`propertyInformations.bathrooms`)}</span>
                    <span>{property?.acf.number_bathrooms}</span>
                </div>
                <div className={styles.row}>
                    <span>{t(`propertyInformations.floor`)}</span>
                    {/* <span>{category?.acf.}</span> */}
                </div>
                <div className={styles.row}>
                    <span>{t(`propertyInformations.totalArea`)}</span>
                    <div>
                        <span>{convertedArea}</span>{' '}
                        <select
                            value={selectedAreaUnit}
                            onChange={e => setSelectedAreaUnit(e.target.value)}
                            className={styles.areaSelect}
                        >
                            <option value="SQM">SQM</option>
                            <option value="SQFT">SQFT</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className={styles.priceWrapper} style={priceWrapperStyle}>
                <div className={styles.price}>{t(`propertyInformations.price`)}</div>
                <div className={styles.priceAmountWrapper}>
                    <span className={styles.priceAmount}>{convertedPrice}</span>
                    <select
                        value={selectedCurrency}
                        onChange={e => setSelectedCurrency(e.target.value)}
                        className={styles.currencySelect}
                    >
                        <option value="SAR">SAR</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                    </select>
                </div>
            </div>

            <div className={styles.buttonWrapper} style={buttonWrapperStyle}>
                <button className={styles.register} onClick={handleFloorPlanClick}>
                    {t(`button.unitFloorPlan`)}
                </button>
                <button className={styles.register} onClick={handleVRClick}>
                    {t(`button.exploreVR`)}
                </button>
                <button className={styles.callback} onClick={handleRegisterClick}>
                    {t(`button.register`)}
                </button>
            </div>
        </div>
    )
}
