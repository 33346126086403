import { CSSProperties, RefObject, useContext, useState } from 'react'
import ZoomToolbarButton from '../ZoomToolbarButton/ZoomToolbarButton'
import styles from './ZoomToolbar.module.css'
import { useTranslation } from 'react-i18next'
import { PageContext } from '../../../contexts'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'

const ZoomToolbar: React.FC<{ transformWrapper?: ReactZoomPanPinchRef }> = ({ transformWrapper }) => {
    const { i18n } = useTranslation()
    const currentLanguage = i18n.language
    const [selectedButton, setSelectedButton] = useState<string | null>(null)
    const [isFullScreen, setIsFullScreen] = useState(false)
    // const { mapZoom, setMapZoom } = useContext(PageContext)

    const containerStyle: CSSProperties = {
        flexDirection: currentLanguage === 'ar' ? 'row-reverse' : 'row',
        right: currentLanguage === 'ar' ? 'unset' : '2%',
        left: currentLanguage === 'ar' ? '2%' : 'unset',
    }

    const handleButtonClick = (name: string) => {
        setSelectedButton(name)

        if (name === 'frame' && !isFullScreen) {
            document.documentElement.requestFullscreen()
            setIsFullScreen(true)
        } else if (name === 'frame' && isFullScreen) {
            document.exitFullscreen()
            setIsFullScreen(false)
        } else if (name === 'zoom') {
            transformWrapper?.zoomIn(1)
        } else if (name === 'unzoom') {
            transformWrapper?.zoomOut(1)
        }
    }

    return (
        <div className={styles.container} style={containerStyle}>
            <ZoomToolbarButton name="unzoom" isSelected={false} onClick={() => handleButtonClick('unzoom')} />
            <ZoomToolbarButton name="zoom" isSelected={false} onClick={() => handleButtonClick('zoom')} />
            <ZoomToolbarButton
                name="frame"
                isSelected={selectedButton === 'frame'}
                onClick={() => handleButtonClick('frame')}
            />
        </div>
    )
}

export default ZoomToolbar
