import { PageContext } from '../../../contexts'
import { useContext } from 'react'
import styles from './MapLocation.module.css'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

interface Props {
  icon?: JSX.Element
  locationName?: string
  targetPage: string
  altContent?: any
  position: {
    mobile?: { top: string; left: string }
    desktop: { top: string; left: string }
  }
}

const MapLocation: React.FC<Props> = props => {
  const positionStyle = isMobile && props.position.mobile ? props.position.mobile : props.position.desktop
  const { setActiveButton } = useContext(PageContext)
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(props.targetPage)
    setActiveButton(null)
  }

  return (
    <div className={styles.wrapper} style={{ top: positionStyle.top, left: positionStyle.left }} id="map-location">
      <button className={styles.locationWrapper} onClick={handleClick}>
        <div className={styles.locationName}>
          {props.altContent ? props.altContent : props.icon ? props.icon : null}
          <span>{props.locationName ?? null}</span>
        </div>
      </button>
      <div className={styles.pinArea}>
        <div className={styles.pin} />
      </div>
    </div>
  )
}

export default MapLocation
